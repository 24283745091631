import { ReactNode } from 'react'

import type { ObjectWithTranslation } from './transformedRespTypes'

export type LocationState = {
  autoLoggedOut?: boolean
  from?: string
  token?: string
}

export enum HttpStatusCode {
  NOT_FOUND = 404,
  UNAUTHORIZED = 401,
}

export enum PayfortErrorMessages {
  'INVALID_CARD_NUMBER',
  'INVALID_EXPIRY_DATE',
  'CARD_EXPIRED',
  'INSUFFICIENT_FUNDS',
  'SIGNATURE_MISMATCH',
  'INVALID_PAYMENT_OPTION',
  'YOU_HAVE_EXCEEDED_THE_MAXIMUM_NUMBER_OF_ATTEMPTS',
  '3DS_VERIFICATION_DOESNT_MATCH_THE_REQUEST_DETAILS',
}

export enum SurveyServiceTypes {
  VISA_ISSUANCE = 'VISA_ISSUANCE',
  VISA_CANCELLATION = 'VISA_CANCELLATION',
  DISABILITIES_VISA_ISSUANCE = 'DISABILITIES_VISA_ISSUANCE',
  ALTERNATIVE_VISA_ISSUANCE = 'ALTERNATIVE_VISA_ISSUANCE',
  EST_2_IND = 'EST_2_IND',
  IND_2_IND = 'IND_2_IND',
  COMPLAINT_CLOSED = 'COMPLAINT_CLOSED',
}

export type EvisaPayfortOptions = {
  status: 'success' | 'fail' | ''
  message: PayfortErrorMessages
}

export type ValueLabelIconOption<T> = {
  value: T
  labelKey: string
  icon?: ReactNode
}

export type ValueLabelOption<T> = {
  value: T
  labelKey: string
}

export type ContractPagesParams = {
  requestId: string
  offerId: string
}

export enum Lang {
  en = 'en',
  ar = 'ar',
  ur = 'ur',
  am = 'am',
  fil = 'fil',
  hi = 'hi',
  cimode = 'cimode',
}

export type InputState<V = string, E = undefined> = {
  value: V
  validation: InputErrors<E>
}

type InputErrors<E = undefined> = {
  isValid?: boolean
  errors?: Array<string | [string, number]>
  errorDetails?: E
}

export type ValidationErrors = {
  errors: {
    [key: string]: Array<string>
  }
}

export enum ServiceTypes {
  RECRUITMENT = 'RECRUITMENT',
  PRESELECTED = 'PRESELECTED',
  PREFERENCES = 'PREFERENCES',
  DIRECT_CONTRACT = 'DIRECT_CONTRACT',
  PREVISA = 'PREVISA',
  LEASE = 'LEASE',
  NONE = '',
}

export enum ReligionTypes {
  MUSLIM = 1,
  NOT_MUSLIM = 4,
  NONE = 3,
}

export enum UserEligibilityErrorMessages {
  PENDING_REQUEST = 'PENDING_REQUEST',
  RECENT_APPROVED_REQUEST = 'RECENT_APPROVED_REQUEST',
  MAX_VISAS_EXCEEDED = 'MAX_VISAS_EXCEEDED',
  USER_IS_DIED = 'USER_IS_DIED',
  ID_EXPIRED = 'ID_EXPIRED',
  BELOW_ALLOWED_AGE = 'BELOW_ALLOWED_AGE',
  USER_NOT_INVESTOR = 'USER_NOT_INVESTOR',
  USER_HAS_TRAFFIC_VIOLATION = 'USER_HAS_TRAFFIC_VIOLATION',
  STOP_GOVERNMENT_SERVICES = 'STOP_GOVERNMENT_SERVICES',
  TIMEOUT = 'TIMEOUT',
  NOT_FOUND = 'NOT_FOUND',
  CANNOT_OPEN_SPONSER_FILE = 'CANNOT_OPEN_SPONSER_FILE',
  INVALID_BOD = 'INVALID_BOD',
  DOMESTIC_LABOR = 'DOMESTIC_LABOR',
  DEFAULT_ERROR = 'DEFAULT_ERROR',
  NO_OCCUPATION = 'NO_OCCUPATION',
  FOREIGNER_DATA_NOT_FOUND = 'FOREIGNER_DATA_NOT_FOUND',
  USER_NOT_ALLOWED = 'USER_NOT_ALLOWED',
  HAS_PAYMENT_IN_PROGRESS = 'HAS_PAYMENT_IN_PROGRESS',
  MOBILE_NOT_ACTIVE = 'MOBILE_NOT_ACTIVE',
  ERROR_GENERAL = 'ERROR_GENERAL',
  NO_BALANCE = 'NO_BALANCE',
  UNALLOWED_OCCUPATION_FOR_NATIONALITY = 'UNALLOWED_OCCUPATION_FOR_NATIONALITY',
  UNALLOWED_VISAISSUEPLACE_FOR_NATIONALITY = 'UNALLOWED_VISAISSUEPLACE_FOR_NATIONALITY',
}

export enum DisabilityVisaEligibilityErrorMessages {
  // ---
  // Same as UserEligibilityErrorMessages plus additional messages
  // For now, we can't merge enum
  PENDING_REQUEST = 'PENDING_REQUEST',
  RECENT_APPROVED_REQUEST = 'RECENT_APPROVED_REQUEST',
  MAX_VISAS_EXCEEDED = 'MAX_VISAS_EXCEEDED',
  USER_IS_DIED = 'USER_IS_DIED',
  ID_EXPIRED = 'ID_EXPIRED',
  BELOW_ALLOWED_AGE = 'BELOW_ALLOWED_AGE',
  USER_NOT_INVESTOR = 'USER_NOT_INVESTOR',
  USER_HAS_TRAFFIC_VIOLATION = 'USER_HAS_TRAFFIC_VIOLATION',
  STOP_GOVERNMENT_SERVICES = 'STOP_GOVERNMENT_SERVICES',
  TIMEOUT = 'TIMEOUT',
  NOT_FOUND = 'NOT_FOUND',
  CANNOT_OPEN_SPONSER_FILE = 'CANNOT_OPEN_SPONSER_FILE',
  INVALID_BOD = 'INVALID_BOD',
  DOMESTIC_LABOR = 'DOMESTIC_LABOR',
  DEFAULT_ERROR = 'DEFAULT_ERROR',
  NO_OCCUPATION = 'NO_OCCUPATION',
  FOREIGNER_DATA_NOT_FOUND = 'FOREIGNER_DATA_NOT_FOUND',
  USER_NOT_ALLOWED = 'USER_NOT_ALLOWED',
  HAS_PAYMENT_IN_PROGRESS = 'HAS_PAYMENT_IN_PROGRESS',
  ERROR_GENERAL = 'ERROR_GENERAL',
  // ----
  DISABILITY_MAX_VISAS_EXCEEDED = 'DISABILITY_MAX_VISAS_EXCEEDED',
  DISABILITY_NOT_APPLICABLE = 'DISABILITY_NOT_APPLICABLE',
  DISABILITY_NO_OCCUPATION_EXEMPTION = 'DISABILITY_NO_OCCUPATION_EXEMPTION',
  DISABILITY_NOT_FOUND = 'DISABILITY_NOT_FOUND',
  DISABILITY_EMPTY_RESPONSIBLE_NAME = 'DISABILITY_EMPTY_RESPONSIBLE_NAME',
  MOSA_SERVICE_DOWN = 'MOSA_SERVICE_DOWN',
  NOT_REGISTERED_MOSA = 'NOT_REGISTERED_MOSA',
}

export enum FarmerEligibilityErrorMessages {
  // ---
  // Same as UserEligibilityErrorMessages plus additional messages
  // For now, we can't merge enum
  PENDING_REQUEST = 'PENDING_REQUEST',
  RECENT_APPROVED_REQUEST = 'RECENT_APPROVED_REQUEST',
  MAX_VISAS_EXCEEDED = 'MAX_VISAS_EXCEEDED',
  USER_IS_DIED = 'USER_IS_DIED',
  ID_EXPIRED = 'ID_EXPIRED',
  BELOW_ALLOWED_AGE = 'BELOW_ALLOWED_AGE',
  USER_NOT_INVESTOR = 'USER_NOT_INVESTOR',
  USER_HAS_TRAFFIC_VIOLATION = 'USER_HAS_TRAFFIC_VIOLATION',
  STOP_GOVERNMENT_SERVICES = 'STOP_GOVERNMENT_SERVICES',
  TIMEOUT = 'TIMEOUT',
  NOT_FOUND = 'NOT_FOUND',
  CANNOT_OPEN_SPONSER_FILE = 'CANNOT_OPEN_SPONSER_FILE',
  INVALID_BOD = 'INVALID_BOD',
  DOMESTIC_LABOR = 'DOMESTIC_LABOR',
  DEFAULT_ERROR = 'DEFAULT_ERROR',
  NO_OCCUPATION = 'NO_OCCUPATION',
  FOREIGNER_DATA_NOT_FOUND = 'FOREIGNER_DATA_NOT_FOUND',
  USER_NOT_ALLOWED = 'USER_NOT_ALLOWED',
  HAS_PAYMENT_IN_PROGRESS = 'HAS_PAYMENT_IN_PROGRESS',
  ERROR_GENERAL = 'ERROR_GENERAL',
  // ----
  FARMER_CREDIT_NOT_FOUND = 'FARMER_CREDIT_NOT_FOUND',
  NO_ESTABLISHMENTS = 'NO_ESTABLISHMENTS',
  NEW_ESTABLISHMENT_NOT_FOUND = 'NEW_ESTABLISHMENT_NOT_FOUND',
}

export enum FarmerAgriculturalRequestErrorMessages {
  INVALID_ID_NUMBER = 'INVALID_ID_NUMBER',
  LICENSE_EXPIRED = 'LICENSE_EXPIRED',
  ESTABLISHMENT_EXIST_ALREADY = 'ESTABLISHMENT_EXIST_ALREADY',
  GENERAL_ERROR = 'GENERAL_ERROR',
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_PHONE = 'INVALID_PHONE',
  OWNER_HAS_PENDING_ESTABLISHMENT_REQUEST = 'OWNER_HAS_PENDING_ESTABLISHMENT_REQUEST',
}

export enum FarmerTransferabilityErrorMessages {
  OCCUPATION_NOT_FARMER = 'OCCUPATION_NOT_FARMER',
  CREDIT_FOR_OCCUPATION_NOT_FOUND = 'CREDIT_FOR_OCCUPATION_NOT_FOUND',
  USER_IS_LABOR_EMPLOYER = 'USER_IS_LABOR_EMPLOYER',
  EMPLOYER_HAS_NO_ACCOUNT = 'EMPLOYER_HAS_NO_ACCOUNT',
  DEFAULT_ERROR = 'DEFAULT_ERROR',
  LABORER_NOT_FOUND = 'LABORER_NOT_FOUND',
  LABORER_STATUS_NOT_WORKING = 'LABORER_STATUS_NOT_WORKING',
  TIMEOUT = 'TIMEOUT',
  ERROR_GENERAL = 'ERROR_GENERAL',
  LABORER_ESTABLISHMENT_ACTIVITY_NOT_FARMING = 'LABORER_ESTABLISHMENT_ACTIVITY_NOT_FARMING',
  LABORER_HAS_ACTIVE_TRANSFER_REQUEST = 'LABORER_HAS_ACTIVE_TRANSFER_REQUEST',
  NEW_ESTABLISHMENT_NOT_FOUND = 'NEW_ESTABLISHMENT_NOT_FOUND',
  PLEASE_REFRESH = 'PLEASE_REFRESH',
  MISSING_FILES = 'MISSING_FILES',
}

export type Await<T> = T extends {
  then(onfulfilled?: (value: infer U) => unknown): unknown
}
  ? U
  : T

export enum UserStatus {
  ACCEPT = 'ACCEPT',
  CANCELLED = 'CANCELLED',
  REJECT = 'REJECT',
  EXPIRED = 'EXPIRED',
}

export enum ProsStatus {
  ACCEPT = 'ACCEPT',
  PENDING = 'PENDING',
  REJECT = 'REJECT',
  EXPIRED = 'EXPIRED',
}

export enum ContractRequestStatus {
  OFFER_RECEIVED = 'OFFER_RECEIVED',
  INITIATED = 'INITIATED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  REJECTED = 'REJECTED',
}

export enum ContractStatus {
  PENDING = 'PENDING',
  ARRIVED = 'ARRIVED',
  VALID = 'VALID',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  LABOR_SELECTED = 'LABOR_SELECTED',
  TICKET_ISSUED = 'TICKET_ISSUED',
  ON_BOARDING = 'ON_BOARDING',
  MEDICAL = 'MEDICAL',
  TRAINING = 'TRAINING',
  VISA_ISSUED = 'VISA_ISSUED',
  TICKET = 'TICKET',
  E_CANCELED = 'E_CANCELED',
  REJECTED = 'REJECTED',
  VISA_ISSUE_PENDING = 'VISA_ISSUE_PENDING',
  DELEGATED_CANCELLATION = 'DELEGATED_CANCELLATION',
  INITIATED = 'INITIATED',
  WAITING_INSURANCE_SELECTION = 'WAITING_INSURANCE_SELECTION',
}

export enum ContractCancelRequest {
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
}

export type PaginatorBE<T> = {
  last_page: number
  data: T[]
}

export type Paginator<T> = {
  lastPage: number
  data: T[]
}

export enum PendingUnifiedEligibilityIssue {
  MAX_VISAS = 'MAX_VISAS',
  USER_HAS_TRAFFIC_VIOLATION = 'USER_HAS_TRAFFIC_VIOLATION',
  STOP_GOVERNMENT_SERVICES = 'STOP_GOVERNMENT_SERVICES',
  NO_BALANCE = 'NO_BALANCE',
  NOT_ALLOWED_OCCUPATION = 'NOT_ALLOWED_OCCUPATION',
  USER_IS_DEAD = 'USER_IS_DEAD',
  UNKNOWN = 'UNKNOWN',
  INVALID_BOD = 'INVALID_BOD',
}

export enum FarmerVisaIssueRequestStatus {
  ALL = '',
  PENDING = 1,
  APPROVED = 2,
  REJECTED = 3,
  WAITING_PAYMENT = 5,
  INITIAL = 10,
}

export enum FarmerVisaStatus {
  SENT_TO_BANK = 0,
  ISSUED = 1,
  USED = 2,
  CANCELED = 3,
}

export enum VisaIssueRequestStatus {
  all = '',
  approved = 2,
  pending = 1,
  cancel = 4,
  waitingForPayment = 5,
  waitingForContract = 9,
  rejected = 3,
  initial = 10,
  expired = 5,
}

export enum VisaStatus {
  sentToBank = 0,
  issued = 1,
  used = 2,
  canceled = 3,
  unissued = 4,
  sentToMOFA = 5,
  sentToMOFAAuto = 6,
}

export enum IssueRequestTypes {
  typeRegular = 'typeRegular',
  typeSpecialNeeds = 'typeSpecialNeeds',
  typeAlternative = 'typeAlternative',
}

export enum OfferStatus {
  WAITING_RESPONSE_OFFICE = 'WAITING_RESPONSE_OFFICE',
  CANCELED = 'CANCELED',
  REJECT_BY_PRO = 'REJECT_BY_PRO',
  REJECT_BY_USER = 'REJECT_BY_USER',
  EXPIRED_BY_PRO = 'EXPIRED_BY_PRO',
  EXPIRED_BY_USER = 'EXPIRED_BY_USER',
}

export enum BillStatusKey {
  PAID = 'PAID',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  REFUNDED = 'REFUNDED',
}

export enum NoticeStatus {
  PENDING = 1,
  APPROVED_WAITING_PAYMENT = 2,
  REJECTED = 3,
  CANCEL = 4,
  APPROVED_PAID = 5,
  COMPLETLY_APPROVED = 6,
  COMPLETLY_REJECTED = 7,
}

export enum SupportTicketStatus {
  IN_PROGRESS = 'in_progress',
  WAITING_FOR_REQUEST = 'waiting_for_requester',
  COMPLETED = 'completed',
  CLOSED = 'closed',
}

export enum BillStatus {
  DEFAULT = 0,
  PENDING = 1,
  PAID = 2,
  EXPIRED = 3,
  REFUNDED = 4,
}

export enum ComplaintTicketStatus {
  IN_PROGRESS = 'in_progress',
  ESCALATED = 'escalated',
  ATTACHMENTS_CLOSED = 'attachments_closed',
  PENDING_USER_RESPONSE = 'pending_user_response',
  CLOSED = 'closed',
  PENDING_USER_RESPONSE_VERIFICATION = 'pending_user_response_verification',
  WAITING_FOR_CC_L1_VERIFICATION = 'waiting_for_cc_l1_verification',
  WAITING_FOR_CC_L2_VERIFICATION = 'waiting_for_cc_l2_verification',
  WAITING_FOR_TAKAMOL_VERIFICATION = 'waiting_for_takamol_verification',
  PENDING_EMPLOYER_VERIFICATION = 'waiting_for_requester',
  CC_SOLUTION_ACCEPTANCE = 'cc_solution_acceptance',
  PRO_SERVICE_SUSPENDED = 'pro_services_suspended',
  WAITING_FOR_PRO_VERIFICATION = 'waiting_for_pro_verification',
  WAITING_FOR_MHRSD_VERIFICATION = 'waiting_for_mhrsd_verification',
  WAITING_FOR_EMPLOYER_ATTACHMENTS = 'waiting_for_employer_attachments',
  WAITING_FOR_PRO = 'waiting_for_pro',
  WAITING_FOR_REQUESTER = 'waiting_for_requester',
}
export enum ComplaintTicketClosureCategory {
  PRO_TEMPORARY_SUSPENDED = 'pro_temporary_suspended',
  PRO_SOLUTION_VERIFIED = 'pro_solution_verified',
  PRO_LICENSE_SUSPENDED = 'pro_license_suspended',
  PRO_EXECUTED_DECISION = 'pro_executed_decision',
  PRO_ALLOWED_TO_CONTACT_MUSANED = 'pro_allowed_to_contact_musaned',
}

export enum ComplaintTicketCountDownStatus {
  running = 'running',
}

export enum ComplaintsRoles {
  PRO = 'PRO',
  CUSTOMER_CARE = 'CUSTOMER_CARE',
  MLSD = 'MLSD',
}

export enum ComplaintsAuthors {
  PRO = 'musanedcomplaintspro',
  EMPLOYER = 'musanedcomplaints',
}

export enum EToILegacyRequestsStatus {
  PENDING = 1,
  WAITING_SPONSOR_APPROVAL = 2,
  WAITING_MLSD_APPROVAL = 3,
  APPROVED = 4,
  REJECTED = 5,
  EXPIRED = 6,
}

export enum ItoERequestsStatus {
  WAITING_EMPLOYER_APPROVAL = 1,
  WAITING_ABSHER = 2,
  APPROVED = 3,
  REJECTED_NIC = 4,
  EXPIRED = 5,
  REJECTED_EMPLOYER = 6,
  PROCESSING = 7,
  CANCELLED = 8,
}

export enum EToIRequestStatus {
  WAITING_EMPLOYER_RESPONSE = 'WAITING_EMPLOYER_RESPONSE',
  PENDING_BACKOFFICE = 'PENDING_BACKOFFICE',
  REJECTED = 'REJECTED',
  REJECTED_FOR_FINANCIAL_INELIGIBILITY = 'REJECTED_FOR_FINANCIAL_INELIGIBILITY',
  VALID = 'VALID',
  CANCELLED = 'CANCELLED',
  WAITING_NIC_CORRECTION = 'WAITING_NIC_CORRECTION',
  CANCELLED_FOR_INELIGIBILITY = 'CANCELLED_FOR_INELIGIBILITY',
  UNDER_FINAL_REVIEW = 'UNDER_FINAL_REVIEW',
  COMPLETE = 'COMPLETE',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  PAYMENT_EXPIRED = 'PAYMENT_EXPIRED',
  WAITING_FINANCIAL_DOCUMENTS = 'WAITING_FINANCIAL_DOCUMENTS',
  WAITING_ELIGIBILITY_CORRECTION = 'WAITING_ELIGIBILITY_CORRECTION',
  RESPONSE_EXPIRED = 'RESPONSE_EXPIRED',
  CANCELLED_FOR_INCOMPLETE_PROCEDURES = 'CANCELLED_FOR_INCOMPLETE_PROCEDURES',
  CANCELED_BY_PRO = 'CANCELED_BY_PRO',
  CANCELLED_BEFORE_PAYMENT_BY_PRO = 'CANCELLED_BEFORE_PAYMENT_BY_PRO',
}

export enum EToIAppendixStatus {
  NEW = 'NEW',
  VALID = 'VALID',
  REPLACED = 'REPLACED',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  TRANSFERRED = 'TRANSFERRED',
}

export enum EToIReplacementStatus {
  NEW = 'NEW',
  CANCELED = 'CANCELED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELED_BY_EMPLOYER = 'CANCELED_BY_EMPLOYER',
  CANCELED_BY_PRO = 'CANCELED_BY_PRO',
}

export enum EToITransferRequestStatus {
  SENDING = 'SENDING',
  SENT = 'SENT',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  WAITING_ELIGIBILITY_CORRECTION = 'WAITING_ELIGIBILITY_CORRECTION',
  CANCELLED_FOR_INELIGIBILITY = 'CANCELLED_FOR_INELIGIBILITY',
}

export enum FarmerTransferRequestsStatus {
  WAITING_SPONSOR_APPROVAL = 1,
  FINAL_REVIEW = 2,
  CANCELLED = 3,
  APPROVED = 4,
  REJECTED = 5,
  EXPIRED_SPONSOR = 6,
  EXPIRED_MINISTRY = 7,
}

export enum EmploymentStatus {
  NONE = 0,
  PUBLIC_SECTOR_EMPLOYEE = 1,
  RETIRED_PUBLIC_SECTOR = 2,
  PRIVATE_SECTOR_EMPLOYEE = 3,
  RETIRED_PRIVATE_SECTOR = 4,
  // NEVER_WORKED = 5, // currently unused
  // MILITARY_SECTOR_EMPLOYEE = 6, // currently unused
  // RETIRED_MILITARY_SECTOR = 7, // currently unused
  // OTHER = 8, // currently unused
  NOT_EMPLOYEE = 9,
  INVESTOR = 10,
  RESIDENT = 11,
}

export type ContractComplaintCategory = {
  id: number
  timeRestricted: boolean
  selectableStartingAtIncludesDuration: boolean
  selectableWindow: number
  selectableForValidContracts: boolean
  selectableForCanceledContracts: boolean
  selectableForNonCompensation?: boolean
  selectableForRecruitment?: boolean
  selectableForAllCases?: boolean
}

export enum PaymentMethods {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  MADA = 'MADA',
  SADADBILL = 'SADAD-BILL',
  APPLE_PAY = 'APPLE-PAY',
}

export enum Banks {
  STC = 'STC',
  URPAY = 'URPAY',
  ALINMAPAY = 'ALINMAPAY',
  ENJAZ = 'ENJAZ',
  MOBILITYPAY = 'MOBILITYPAY',
}

export type InstallmentFee = {
  id: number
  numberOfInstallment: number
  value: number
  key: InstallmentKeysType
}

export enum FeesType {
  PERCENTAGE = 'Percentage',
  FIXED = 'Fixed',
}

export enum RateType {
  REDUCING_BALANCE = 'Reducing balance',
  FLAT = 'Flat',
}

export type InstallmentPlanDetail = {
  minimumAmount: number
  maximumAmount: number
  numberOfInstallment: number
  planCode: string
  feeAmount: number
  feeDisplayValue: number
  feeType: FeesType
  rateType: RateType
}

export type InstallmentsPlansIssuerDetail = {
  bins: Array<{ bin: string }>
  formula: string
  planDetails: Array<InstallmentPlanDetail>
  processingFeesMessage: ObjectWithTranslation
  issuerCode: string
  issuerLogoAr: string
  issuerLogoEn: string
  issuerName: ObjectWithTranslation
  termsAndConditionLinkEn: string
  termsAndConditionLinkAr: string
}

export enum IssueNewVisaRequestStatusError {
  NO_BALANCE = 'NO_BALANCE',
}

export enum CountryCodes {
  SAUDI_ARABIA = 'sa',
  PHILIPPINES = 'ph',
}

export enum SubmitVisaWithContractRequestError {
  BLACKLISTED = 'BLACKLISTED',
  ERROR_GENERAL = 'ERROR_GENERAL',
  VISA_NOT_ALLOWED_FOR_ONLINE = 'VISA_NOT_ALLOWED_FOR_ONLINE',
}

export type Labor = {
  amountOutstanding: number
  contractNo: string
  laborBorder: string
  laborID: string
  laborName: string
  laborNationality: string
  occupationName: string
  payrollAmt: number
}

export type GeneralFailResponse<T = string> = {
  error?: {
    message: T
    code: number
  }
}

export type VisaIssueRequestServiceFields = {
  rejectionReasons: ObjectWithTranslation[]
  pendingUnifiedEligibilityIssue: PendingUnifiedEligibilityIssue | null
  status: VisaIssueRequestStatus
}

export enum BillRequestType {
  REGULAR_VISA = 1,
  FARMER_VISA = 2,
  NOTICE = 3,
}

export enum UploadFinancialDocumentErrorMessage {
  USER_UPLOAD_FAILED = 'USER_UPLOAD.FAILED',
  LOW_QUALITY = 'LOW_QUALITY',
  OCR_SYSTEM_EXCEPTION = 'OCR_SYSTEM_EXCEPTION',
  OCR_TIMEOUT = 'OCR_TIMEOUT',
  GENERAL_ERROR = 'GENERAL_ERROR',
  DEFAULT_ERROR = 'DEFAULT_ERROR',
}

export type ProStatistic = {
  id: string
  arrivalDurationRating: number
  arrivalDurationRatingsCount: number
  recommendabilityRating: number
  recommendabilityRatingsCount: number
  responsivenessRating: number
  responsivenessRatingsCount: number
  conformityRating: number
  conformityRatingsCount: number
}

export type StringRecord<T> = Partial<Record<string, T>>
export type ValuesFromRecord<T extends StringRecord<unknown>> = Array<NonNullable<T['key']>>

export type InstallmentKeysType =
  | 'three_months_installments_fees'
  | 'six_months_installments_fees'
  | 'twelve_months_installments_fees'

export enum ContractExtensionStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export type LaborInfo = {
  name?: string
  id?: string
  nationality?: string
  occupation?: string
  establishmentName?: string
}

export enum Platform {
  WEB = 'WEB',
  MOBILE = 'MOBILE',
}

export type Answer = { id: number; rating: number }

export enum ContractAuthRequestStatus {
  VALID = 'VALID',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  WAITING_LABOR_RESPONSE = 'WAITING_LABOR_RESPONSE',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  REQUEST_EXPIRED = 'REQUEST_EXPIRED',
  CONTRACT_EXPIRED = 'CONTRACT_EXPIRED',
}

export enum AuthContractLaborerStatus {
  UNDER_PROCESS = 'UNDER_PROCESS',
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

export enum ContractAuthAppendixStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
}

export enum PlaceOfResidencyType {
  NOT_SEPARATE = 'NOT_SEPARATE',
  SEPARATE = 'SEPARATE',
}

export enum ResidencyDescriptionType {
  SEPARATE_ROOM_WITH_SEPARATED_TOILET = 'SEPARATE_ROOM_WITH_SEPARATED_TOILET',
  SEPARATE_ROOM_WITH_SHARED_BATHROOM = 'SEPARATE_ROOM_WITH_SHARED_BATHROOM',
  SHARED_ROOM_WITH_SEPARATE_TOILET = 'SHARED_ROOM_WITH_SEPARATE_TOILET',
  SHARED_ROOM_WITH_SHARED_BATHROOM = 'SHARED_ROOM_WITH_SHARED_BATHROOM',
  OTHER = 'OTHER',
}

export enum RelativeRelationType {
  MOTHER = 'MOTHER',
  FATHER = 'FATHER',
  SISTER = 'SISTER',
  BROTHER = 'BROTHER',
  SON = 'SON',
  DAUGHTER = 'DAUGHTER',
  OTHER = 'OTHER',
}

export enum ComplaintStatuses {
  CLOSED = '6',
  WAITING_FOR_CC = '12706',
  CC_SOLUTION_APPROVAL = '12707',
  WAITING_FOR_PRO = '12602',
  WAITING_FOR_MLSD_L2 = '12708',
  MLSD_SOLUTION_APPROVAL = '12709',
  CC_SOLUTION_ACCEPTANCE = '12712',
  PRO_SOLUTION_ACCEPTANCE = '12711',
  WAITING_FOR_ATTACHMENTS = '14700',
  PENDING_EMPLOYER_VERIFICATION = '13200',
  MHRSD_SOLUTION_PROVIDED = '13703',
  WAITING_FOR_MHRSD_VERIFICATION = '13301',
  WAITING_FOR_HALOUL = '15500',
  WAITING_FOR_TAKAMOL_VERIFICATION = '13203',
  VALIDATE_SUSPNDED_PROS = '15501',
  COMPLAINT_INITIAL_STATE = 'COMPLAINT_INITIAL_STATE',
}

export enum NationalIdTypes {
  NATIONAL_ID = 'NATIONAL_ID',
  IQAMA_ID = 'IQAMA_ID',
  UNKNOWN = 'UNKNOWN',
}

export enum InsuranceStatuses {
  PENDING_EMPLOYER_PAYMENT = 'PENDING_EMPLOYER_PAYMENT',
  PENDING_OFFICE_PAYMENT = 'PENDING_OFFICE_PAYMENT',
  ISSUED = 'ISSUED',
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  PENDING_POLICY_ISSUANCE = 'PENDING_POLICY_ISSUANCE',
}

export type EToIRequstUrlParams = {
  requestId: string
}

export enum IToIRequestsStatus {
  WAITING_LABORER_APPROVAL = 'WAITING_LABORER_APPROVAL',
  WAITING_EMPLOYER_APPROVAL = 'WAITING_EMPLOYER_APPROVAL',
  EXPIRED_LABORER_APPROVAL = 'EXPIRED_LABORER_APPROVAL',
  REJECTED_LABORER_APPROVAL = 'REJECTED_LABORER_APPROVAL',
  EXPIRED_EMPLOYER_APPROVAL = 'EXPIRED_EMPLOYER_APPROVAL',
  REJECTED_EMPLOYER_APPROVAL = 'REJECTED_EMPLOYER_APPROVAL',
  WAITING_BACKOFFICE = 'WAITING_BACKOFFICE',
  REJECTED_BACKOFFICE = 'REJECTED_BACKOFFICE',
  WAITING_EMPLOYER_ELIGIBILITY = 'WAITING_EMPLOYER_ELIGIBILITY',
  REJECTED_EMPLOYER_ELIGIBILITY = 'REJECTED_EMPLOYER_ELIGIBILITY',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  EXPIRED_PAYMENT = 'EXPIRED_PAYMENT',
  PENDING_HRSD = 'PENDING_HRSD',
  REJECTED_HRSD = 'REJECTED_HRSD',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  WAITING_FINANCIAL_DOCUMENTS = 'WAITING_FINANCIAL_DOCUMENTS',
  EXPIRED_FINANCIAL_DOCUMENTS = 'EXPIRED_FINANCIAL_DOCUMENTS',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
}

export enum AuthContractRegions {
  AL_RIYADH = '1',
  EASTERN_REGION = '2',
  MECCA_AL_MOKARAMA = '3',
  AL_MADINA_AL_MOAWRA = '8',
  AL_QASSIM = '9',
  ASSIR = '10',
  TABOUK = '11',
  HAIL = '12',
  NORTHERN_BORDERS = '13',
  JAZAN = '14',
  NAJRAN = '15',
  AL_BAHA = '16',
  AL_JOUF = '17',
}

export enum OccupationType {
  EMPLOYEE_OF_THE_PUBLIC_SECTOR = 'EMPLOYEE_OF_THE_PUBLIC_SECTOR',
  RETIREE_OF_THE_PUBLIC_SECTOR = 'RETIREE_OF_THE_PUBLIC_SECTOR',
  EMPLOYEE_OF_THE_PRIVATE_SECTOR = 'EMPLOYEE_OF_THE_PRIVATE_SECTOR',
  RETIREE_OF_THE_PRIVATE_SECTOR = 'RETIREE_OF_THE_PRIVATE_SECTOR',
  UNEMPLOYED = 'UNEMPLOYED',
  INVESTOR = 'INVESTOR',
  RESIDENT = 'RESIDENT',
}

export type NationalityTranslationType = {
  nationality: string
  label: string
  labelEn: string
}

export type DateState = InputState<DateType, { isDayValid: boolean; isMonthValid: boolean; isYearValid: boolean }>

export type DateType = {
  day: number
  month: number
  year: number
}

export enum Gender {
  MALE = 0,
  FEMALE = 1,
}

export type ProPerformance = {
  arrivalRate: number
  commitmentRate: number
  resolutionRate: number
  lateResolutionRate: number
  averageRate: number
  proId: number
}

export enum SupportServices {
  CONTRACT = 'CONTRACT',
  VISA = 'VISA',
  SERVICE_TRANSFER = 'SERVICE_TRANSFER',
  LABORER_INTERVIEW = 'LABORER_INTERVIEW',
  ACCOUNT = 'ACCOUNT',
  OTHER = 'OTHER',
}

type ServiceDataItem = Record<string, Array<string>> | string
export type ServiceData = Array<Record<string, Array<ServiceDataItem>>>

export type ServiceItem = {
  label: string
  steps?: ServiceData
  prerequisites?: ServiceData
  relatedJourneys?: ServiceData
  relatedServices?: ServiceData
  cost?: string
  duration?: string
  channels?: Array<string>
  paymentChannels?: Array<string>
}

export enum ServiceType {
  LABORER_INTERVIEW = 'laborerInterview',
  DOMESTIC_LABOR_VISA = 'domesticLaborVisa',
  DISABILITY_VISA = 'disabilityVisa',
  ALTERNATIVE_VISA = 'alternativeVisa',
  AGRICULTURAL_VISA = 'agricultureVisa',
  RECRUITMENT_BY_PREFERENCES = 'recruitmentByPreferences',
  RECRUITMENT_BY_PRE_SELECTED = 'recruitmentByPreSelected',
  MAAROFA = 'maarofa',
  AUTH_CONTRACT = 'contractAuthentication',
  COMPLAINTS = 'complaints',
  REFUND_REQUEST = 'refundRequest',
  WPS = 'wageProtectionSystem',
  FEE_EXEMPTION = 'feeExemption',
  I_TO_I = 'individualToIndividual',
  E_TO_I = 'companyToIndividual',
  FARMER_TRANSFER = 'transferFarmers',
  I_TO_E = 'individualToEstablishment',
}

export enum VisaTypes {
  FARMER_VISA = 'FARMER_VISA',
  DOMESTIC_VISA = 'DOMESTIC_VISA',
  DISABLED_VISA = 'DISABLED_VISA',
}

export enum ManagerAuthStatusTypes {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum PerformanceRates {
  GOLD = 'gold',
  SILVER = 'silver',
  BRONZE = 'bronze',
  NOT_APPLICABLE = 'notApplicable',
}

export enum SupportComplaintCategories {
  LIVING_CONDITIONS = 'LIVING_CONDITIONS',
  WORK_CONDITIONS = 'WORK_CONDITIONS',
  CONTRACT_VIOLATIONS = 'CONTRACT_VIOLATIONS',
  HARASSMENT_ABUSE = 'HARASSMENT_ABUSE',
  DISCRIMINATION = 'DISCRIMINATION',
  ACCESS_SUPPORT_SERVICES = 'ACCESS_SUPPORT_SERVICES',
  EMPLOYMENT_ISSUES = 'EMPLOYMENT_ISSUES',
  COMMUNICATION_BARRIERS = 'COMMUNICATION_BARRIERS',
  SOCIAL_ISOLATION = 'SOCIAL_ISOLATION',
  RETALIATION_PUNISHMENT_COMPLAINING = 'RETALIATION_PUNISHMENT_COMPLAINING',
}

export enum SupportDocumentTypes {
  PHOTOS = 'PHOTOS',
  MEDICAL_REPORT = 'MEDICAL_REPORT',
  WHATS_APP_MESSAGE = 'WHATS_APP_MESSAGE',
}

export enum SupportComplaintCategoryIds {
  LIVING_CONDITIONS = '22000',
  WORK_CONDITIONS = '22001',
  CONTRACT_VIOLATIONS = '22002',
  HARASSMENT_ABUSE = '22003',
  DISCRIMINATION = '22004',
  ACCESS_SUPPORT_SERVICES = '22005',
  EMPLOYMENT_ISSUES = '22006',
  COMMUNICATION_BARRIERS = '22007',
  SOCIAL_ISOLATION = '22008',
  RETALIATION_PUNISHMENT_COMPLAINING = '22009',
}

export enum SupportComplaintStatuses {
  'PENDING_VERIFICATION' = 'Pending Verification',
  'OPEN' = 'Open',
  'ESCALATED_TO_MINISTRY' = 'Escalated to Ministry',
  'WAITING_FOR_CUSTOMER' = 'Waiting for customer',
  'UNDER_PROCESSING_L1' = 'Under Processing L1',
  'UNDER_PROCESSING_L2' = 'Under Processing L2',
  'UNDER_PROCESSING_L3' = 'Under Processing L3',
  'UNDER_PROCESSING_L4' = 'Under Processing L4',
  'UNDER_PROCESSING_L5' = 'Under Processing L5',
  'DECISION_RECEIVED' = 'Decision Received',
  'RESOLVED' = 'Resolved',
  'COMPLETED' = 'Completed',
}

export type OfferService = {
  name: string
  description: string
  id: number
  offerRequestId: number
  recruitedAddonCategoryId: number
  note: string
  cost: number
  isUserAccepted: boolean
  createdAt: string
  updatedAt: string
  serviceProvider: string
  duration: number
}

export enum ListingRequestStatus {
  WAITING_LABORER_APPROVAL = 'WAITING_LABORER_APPROVAL',
  WAITING_EMPLOYER_CONFIRMATION = 'WAITING_EMPLOYER_CONFIRMATION',
  CANCELLED_EMPLOYER = 'CANCELLED_EMPLOYER',
  DEACTIVATED_EMPLOYER = 'DEACTIVATED_EMPLOYER',
  ACTIVE = 'ACTIVE',
  REJECTED_LABORER_APPROVAL = 'REJECTED_LABORER_APPROVAL',
  EXPIRED_LABORER_APPROVAL = 'EXPIRED_LABORER_APPROVAL',
  EXPIRED = 'EXPIRED',
  SELECTED = 'SELECTED',
  TRANSFERRED = 'TRANSFERRED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  REJECTED_TRANSFER = 'REJECTED_TRANSFER',
  PENDING_EDIT_REQUEST = 'PENDING_EDIT_REQUEST',
}

export type EditingRequestFromBE = {
  approval_at: null | string
  created_at: string
  educational_qualification: string
  expire_at: null | string
  id: number
  laborer_city: {
    label_ar: string
    label_en: string
  }
  laborer_mobile_number: string
  laborer_profile: string
  marketplace_id: number
  monthly_salary: number
  skills: Array<{
    id: number
    evisa_id: number
    label_ar: string
    label_en: string
  }>
  status: ListingRequestStatus
  transfer_service_cost: number
  years_of_experience_ksa: number
}

export type ListingCVRequestFromBE = {
  id: number
  birth_date: string
  experience_in_saudi: number
  gender: string
  iqama_number: string
  laborer_profile: string
  martial_status: string
  name: string
  name_en: string
  city: {
    id: number
    evisa_id: number
    label_ar: string
    label_en: string
  }
  occupation: {
    label: string
    label_en: string
  }
  nationality: {
    label: string
    label_en: string
  }
  qualification: string | null
  religion: string
  salary: number
  employer_mobile: string
  employer_name: string
  employer_city: string
  status: ListingRequestStatus
  created_at: string
  laborer_mobile: string
  transfer_service_cost?: number
  skills: Array<{
    id: number
    evisa_id: number
    label_ar: string
    label_en: string
  }>
  expire_at: string | null
  editing_requests: Array<EditingRequestFromBE>
}

export type ListingCVRequest = {
  id: number
  editId: number
  approvalAt: string | null
  occupation: ObjectWithTranslation
  employer: string
  status: ListingRequestStatus
  editStatus?: ListingRequestStatus
  birthDate: string
  experienceInSaudi: number
  gender: string
  isMale: boolean
  iqamaNumber: string
  profileImage: string
  martialStatus: string
  name: ObjectWithTranslation
  city: ObjectWithTranslation
  nationality: ObjectWithTranslation
  qualification: string | null
  religion: string
  salary: number
  employerMobile: string
  employerCity: string
  createdAt: string
  laborerMobile: string
  serviceCost?: number
  skills: Array<ObjectWithTranslation>
  expireAt: string
  company?: ObjectWithTranslation
}

export type ListingRequest = {
  id: number
  name: ObjectWithTranslation
  iqamaNumber: string
  occupation: ObjectWithTranslation
  nationality: ObjectWithTranslation
  isOwner: boolean
  status: ListingRequestStatus
  expireAt: string
  createdAt: string
  sentOffers: Array<{
    id: string
    status: string
    updatedAt: string
  }>
  receivedOffers: Array<{
    id: string
    status: string
    updatedAt: string
  }>
}

export enum InterviewLaborerStatus {
  LABORER_AVAILABLE = 'LABORER_AVAILABLE',
  LABORER_NOT_AVAILABLE = 'LABORER_NOT_AVAILABLE',
  NEW_LABORER = 'NEW_LABORER',
}

export enum InterviewStatuses {
  INVITED = 'INVITED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  PAYMENT_EXPIRED = 'PAYMENT_EXPIRED',
  SUBMITTED = 'SUBMITTED',
  COMPLETED = 'COMPLETED',
  WITHDRAW = 'WITHDRAW',
  INCOMPLETE = 'INCOMPLETE',
}

export enum InvitationStatuses {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
}

export enum OfflineSalaryStatus {
  PENDING_LABORER_APPROVAL = 'PENDING_LABORER_APPROVAL',
}

export type TransactionFromBE = {
  id: number
  payment_reference: string
  type: string
  for_month: string | null | [string, string]
  payroll_amount: number
  amount: number
  commitment_percentage: number | null
  payment_timestamp: string
  notes: string | null
  channel: string
  bank_code: string
  deducted_amount: number | null
  percentage_of_deduction: number | null
  violation_type: string | null
  justification_description: string | null
  status?: string
  attachment?: string | null
  created_at?: string
  salary_dates?: string
}

import { takeLatest, call, put } from 'redux-saga/effects'
import { AxiosResponseHeaders } from 'axios'

import Actions from 'redux/actions'
import {
  getEmployerLaborers as getEmployerLaborersAction,
  getContractAuthRequestsList as getContractAuthRequestsListAction,
  createContractAuthRequest as createContractAuthRequestAction,
  getContractAuthDetailedRequests as getContractAuthDetailedRequestsAction,
  cancelContractAuthRequest as cancelContractAuthRequestAction,
  checkLaborerPhoneForContractAuth as checkLaborerPhoneForContractAuthActions,
  createContractAuthAppendix as createContractAuthRequestAppendixAction,
  updateLaborerMobileNumber as updateLaborerMobileNumberAction,
  cancelContractAuthAppendix as cancelContractAuthAppendixAction,
  getAuthContractDraft as getAuthContractDraftAction,
  getSimpleAuthContractInfo as getSimpleAuthContractInfoAction,
  getAuthContractPdf as getAuthContractPdfAction,
  getAuthContract as getAuthContractAction,
  getPendingAppendix as getPendingAppendixAction,
  sendLaborerReport as sendLaborerReportAction,
  cancelLaborerReport as cancelLaborerReportAction,
  checkLaborerHasReport as checkLaborerHasReportAction,
  checkListLaborerEligibility as checkListLaborerEligibilityAction,
  listLaborerCV as listLaborerCVAction,
  cancelListingLaborerCV as cancelListingLaborerCVAction,
  getListingLaborerCVRequest as getListingLaborerCVRequestAction,
  getListingCVsList as getListingCVsListAction,
  selectListingCV as selectListingCVAction,
  getListingRequestList as getListingRequestListAction,
  getListingRequest as getListingRequestAction,
  extendListingRequest as extendListingRequestAction,
  resubmitListingRequest as resubmitListingRequestAction,
  disableListingRequest as disableListingRequestAction,
  acceptListingOffer as acceptListingOfferAction,
  rejectListingOffer as rejectListingOfferAction,
  getListingOffer as getListingOfferAction,
  submitEditListingRequest as submitEditListingRequestAction,
  getEmployerLaborersList as getEmployerLaborersListAction,
  getEmployerLaborerDetails as getEmployerLaborerDetailsAction,
  checkDirectTransfersCount as checkDirectTransfersCountAction,
} from 'redux/actionCreators/contractAuthAPI'
import contractAuthAPI, {
  CreateContractAuthRequestFailResponse,
  GetEmployerLaborersFailResponse,
} from 'api/contractAuthAPI'
import { history } from 'redux/browserHistory'
import { Routes } from 'containers/Routes/routePaths'
import {
  convertContractAuthEmloyerLaborers,
  convertMobileCheckResponse,
} from 'common/utils/convertResponse/convertAuthApiResponse'
import { checkLaborers } from 'common/utils/common'
import authManager from 'common/utils/auth/authManager'

import { getSimpleRequestHandler, putFail, putLoading, putSuccess } from '../utils'

function* getEmployerLaborers(action: ReturnType<typeof getEmployerLaborersAction>) {
  try {
    yield putLoading({ type: Actions.GET_CONTRACT_AUTH_EMLOYER_LABORERS_ASYNC, callAPIActionPayload: action.payload })
    const { response, headers } = yield call<typeof contractAuthAPI.getEmployerLaborers>(
      contractAuthAPI.getEmployerLaborers,
      action.payload.params,
      {},
      { silenceError: false },
    )
    const laborById = convertContractAuthEmloyerLaborers(response)
    yield putSuccess({
      type: Actions.GET_CONTRACT_AUTH_EMLOYER_LABORERS_SUCCESS,
      callAPIActionPayload: action.payload,
      payload: {
        laborById,
      },
      headers,
    })
    !action.payload.params.wps &&
      history.push(authManager.isVipUser() ? Routes.VIP_CONTRACT_AUTH_INSTRUCTION : Routes.CONTRACT_AUTH_INSTRUCTION)
  } catch (err) {
    const { response, headers } = (err || {}) as {
      response?: GetEmployerLaborersFailResponse
      headers?: AxiosResponseHeaders
    }
    yield putFail({
      type: Actions.GET_CONTRACT_AUTH_EMLOYER_LABORERS_FAIL,
      callAPIActionPayload: action.payload,
      payload: {
        errorMessage: response?.error?.message || '',
      },
      headers,
    })
  }
}

function* checkEmployerLaborersForAuthServiceBanner(action: ReturnType<typeof getEmployerLaborersAction>) {
  const { response } = yield call<typeof contractAuthAPI.getEmployerLaborers>(
    contractAuthAPI.getEmployerLaborers,
    action.payload.params,
    {},
    { silenceError: false },
  )
  const showServiceBanner = response && checkLaborers(response)
  if (showServiceBanner) {
    yield put({ type: Actions.OPEN_AUTH_BANNER })
  } else {
    yield put({ type: Actions.CLOSE_AUTH_BANNER })
  }
}

const getContractAuthRequestsList = getSimpleRequestHandler<
  typeof contractAuthAPI.getContractAuthRequestsList,
  ReturnType<typeof getContractAuthRequestsListAction>
>({
  actionLoading: Actions.GET_CONTRACT_AUTH_REQUESTS_LIST_ASYNC,
  actionSuccess: Actions.GET_CONTRACT_AUTH_REQUESTS_LIST_SUCCESS,
  actionFail: Actions.GET_CONTRACT_AUTH_REQUESTS_LIST_FAIL,
  callApiFn: contractAuthAPI.getContractAuthRequestsList,
})

const createContractAuthRequest = getSimpleRequestHandler<
  typeof contractAuthAPI.createContractAuthRequest,
  ReturnType<typeof createContractAuthRequestAction>
>({
  actionLoading: Actions.CREATE_CONTRACT_AUTH_REQUEST_ASYNC,
  actionSuccess: Actions.CREATE_CONTRACT_AUTH_REQUEST_SUCCESS,
  actionFail: Actions.CREATE_CONTRACT_AUTH_REQUEST_FAIL,
  callApiFn: contractAuthAPI.createContractAuthRequest,
})

const getContractAuthDetailedRequest = getSimpleRequestHandler<
  typeof contractAuthAPI.getContractAuthDetailedRequests,
  ReturnType<typeof getContractAuthDetailedRequestsAction>
>({
  actionLoading: Actions.GET_CONTRACT_AUTH_DETAILED_REQUEST_ASYNC,
  actionSuccess: Actions.GET_CONTRACT_AUTH_DETAILED_REQUEST_SUCCESS,
  actionFail: Actions.GET_CONTRACT_AUTH_DETAILED_REQUEST_FAIL,
  callApiFn: contractAuthAPI.getContractAuthDetailedRequests,
})

const cancelContractAuthRequest = getSimpleRequestHandler<
  typeof contractAuthAPI.cancelContractAuthRequest,
  ReturnType<typeof cancelContractAuthRequestAction>
>({
  actionLoading: Actions.CANCEL_CONTRACT_AUTH_REQUEST_ASYNC,
  actionSuccess: Actions.CANCEL_CONTRACT_AUTH_REQUEST_SUCCESS,
  actionFail: Actions.CANCEL_CONTRACT_AUTH_REQUEST_FAIL,
  callApiFn: contractAuthAPI.cancelContractAuthRequest,
})

function* checkLaborerPhoneForContractAuth(action: ReturnType<typeof checkLaborerPhoneForContractAuthActions>) {
  try {
    yield putLoading({
      type: Actions.CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_ASYNC,
      callAPIActionPayload: action.payload,
    })
    const { response, headers } = yield call<typeof contractAuthAPI.checkLaborerPhoneForContractAuth>(
      contractAuthAPI.checkLaborerPhoneForContractAuth,
      action.payload.params,
      {},
      { silenceError: false },
    )
    const payload = convertMobileCheckResponse(response)
    yield putSuccess({
      type: Actions.CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_SUCCESS,
      callAPIActionPayload: action.payload,
      payload,
      headers,
    })
    if (!payload.isUsed) {
      history.push(
        authManager.isVipUser() ? Routes.VIP_CONTRACT_AUTH_CONTRACT_PREVIEW : Routes.CONTRACT_AUTH_CONTRACT_PREVIEW,
      )
    }
  } catch (err) {
    const errorMessage = (err as { response: CreateContractAuthRequestFailResponse }).response.error?.message || ''
    yield putFail({
      type: Actions.CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_FAIL,
      callAPIActionPayload: action.payload,
      payload: { errorMessage },
      headers: {},
    })
  }
}

const createContractAuthRequestAppendix = getSimpleRequestHandler<
  typeof contractAuthAPI.createContractAuthRequestAppendix,
  ReturnType<typeof createContractAuthRequestAppendixAction>
>({
  actionLoading: Actions.CREATE_CONTRACT_AUTH_APPENDIX_ASYNC,
  actionSuccess: Actions.CREATE_CONTRACT_AUTH_APPENDIX_SUCCESS,
  actionFail: Actions.CREATE_CONTRACT_AUTH_APPENDIX_FAIL,
  callApiFn: contractAuthAPI.createContractAuthRequestAppendix,
})

const updateLaborerMobileNumber = getSimpleRequestHandler<
  typeof contractAuthAPI.updateLaborerMobileNumber,
  ReturnType<typeof updateLaborerMobileNumberAction>
>({
  actionLoading: Actions.UPDATE_LABORER_MOBILE_NUMBER_ASYNC,
  actionSuccess: Actions.UPDATE_LABORER_MOBILE_NUMBER_SUCCESS,
  actionFail: Actions.UPDATE_LABORER_MOBILE_NUMBER_FAIL,
  callApiFn: contractAuthAPI.updateLaborerMobileNumber,
})

const cancelContractAuthAppendix = getSimpleRequestHandler<
  typeof contractAuthAPI.cancelContractAuthAppendix,
  ReturnType<typeof cancelContractAuthAppendixAction>
>({
  actionLoading: Actions.CANCEL_CONTRACT_AUTH_APPENDIX_ASYNC,
  actionSuccess: Actions.CANCEL_CONTRACT_AUTH_APPENDIX_SUCCESS,
  actionFail: Actions.CANCEL_CONTRACT_AUTH_APPENDIX_FAIL,
  callApiFn: contractAuthAPI.cancelContractAuthAppendix,
})

const getAuthContractDraft = getSimpleRequestHandler<
  typeof contractAuthAPI.getAuthContractDraft,
  ReturnType<typeof getAuthContractDraftAction>
>({
  actionLoading: Actions.GET_CONTRACT_AUTH_DRAFT_ASYNC,
  actionSuccess: Actions.GET_CONTRACT_AUTH_DRAFT_SUCCESS,
  actionFail: Actions.GET_CONTRACT_AUTH_DRAFT_FAIL,
  callApiFn: contractAuthAPI.getAuthContractDraft,
})

const getSimpleAuthContractInfo = getSimpleRequestHandler<
  typeof contractAuthAPI.getSimpleAuthContractInfo,
  ReturnType<typeof getSimpleAuthContractInfoAction>
>({
  actionLoading: Actions.GET_SIMPLE_AUTH_CONTRACT_INFO_ASYNC,
  actionSuccess: Actions.GET_SIMPLE_AUTH_CONTRACT_INFO_SUCCESS,
  actionFail: Actions.GET_SIMPLE_AUTH_CONTRACT_INFO_FAIL,
  callApiFn: contractAuthAPI.getSimpleAuthContractInfo,
})

const getAuthContractPdf = getSimpleRequestHandler<
  typeof contractAuthAPI.getAuthContractPdf,
  ReturnType<typeof getAuthContractPdfAction>
>({
  actionLoading: Actions.GET_AUTH_CONTRACT_PDF_ASYNC,
  actionSuccess: Actions.GET_AUTH_CONTRACT_PDF_SUCCESS,
  actionFail: Actions.GET_AUTH_CONTRACT_PDF_FAIL,
  callApiFn: contractAuthAPI.getAuthContractPdf,
})

const getAuthContract = getSimpleRequestHandler<
  typeof contractAuthAPI.getAuthContract,
  ReturnType<typeof getAuthContractAction>
>({
  actionLoading: Actions.GET_AUTH_CONTRACT_ASYNC,
  actionSuccess: Actions.GET_AUTH_CONTRACT_SUCCESS,
  actionFail: Actions.GET_AUTH_CONTRACT_FAIL,
  callApiFn: contractAuthAPI.getAuthContract,
})

const getPendingAppendix = getSimpleRequestHandler<
  typeof contractAuthAPI.getPendingAppendix,
  ReturnType<typeof getPendingAppendixAction>
>({
  actionLoading: Actions.GET_PENDING_APPENDIX_ASYNC,
  actionSuccess: Actions.GET_PENDING_APPENDIX_SUCCESS,
  actionFail: Actions.GET_PENDING_APPENDIX_FAIL,
  callApiFn: contractAuthAPI.getPendingAppendix,
})

const sendLaborerReport = getSimpleRequestHandler<
  typeof contractAuthAPI.sendLaborerReport,
  ReturnType<typeof sendLaborerReportAction>
>({
  actionLoading: Actions.SEND_LABORER_REPORT_ASYNC,
  actionSuccess: Actions.SEND_LABORER_REPORT_SUCCESS,
  actionFail: Actions.SEND_LABORER_REPORT_FAIL,
  callApiFn: contractAuthAPI.sendLaborerReport,
})

const cancelLaborerReport = getSimpleRequestHandler<
  typeof contractAuthAPI.cancelLaborerReport,
  ReturnType<typeof cancelLaborerReportAction>
>({
  actionLoading: Actions.CANCEL_LABORER_REPORT_ASYNC,
  actionSuccess: Actions.CANCEL_LABORER_REPORT_SUCCESS,
  actionFail: Actions.CANCEL_LABORER_REPORT_FAIL,
  callApiFn: contractAuthAPI.cancelLaborerReport,
})

const checkLaborerHasReport = getSimpleRequestHandler<
  typeof contractAuthAPI.checkLaborerHasReport,
  ReturnType<typeof checkLaborerHasReportAction>
>({
  actionLoading: Actions.CHECK_LABORER_HAS_REPORT_ASYNC,
  actionSuccess: Actions.CHECK_LABORER_HAS_REPORT_SUCCESS,
  actionFail: Actions.CHECK_LABORER_HAS_REPORT_FAIL,
  callApiFn: contractAuthAPI.checkLaborerHasReport,
})

const checkListLaborerEligibility = getSimpleRequestHandler<
  typeof contractAuthAPI.checkListLaborerEligibility,
  ReturnType<typeof checkListLaborerEligibilityAction>
>({
  actionLoading: Actions.CHECK_LIST_LABORER_ELIGIBILITY_ASYNC,
  actionSuccess: Actions.CHECK_LIST_LABORER_ELIGIBILITY_SUCCESS,
  actionFail: Actions.CHECK_LIST_LABORER_ELIGIBILITY_FAIL,
  callApiFn: contractAuthAPI.checkListLaborerEligibility,
})

const listLaborerCV = getSimpleRequestHandler<
  typeof contractAuthAPI.listLaborerCV,
  ReturnType<typeof listLaborerCVAction>
>({
  actionLoading: Actions.LIST_LABORER_CV_ASYNC,
  actionSuccess: Actions.LIST_LABORER_CV_SUCCESS,
  actionFail: Actions.LIST_LABORER_CV_FAIL,
  callApiFn: contractAuthAPI.listLaborerCV,
})

const cancelListingLaborerCV = getSimpleRequestHandler<
  typeof contractAuthAPI.cancelListingLaborerCV,
  ReturnType<typeof cancelListingLaborerCVAction>
>({
  actionLoading: Actions.CANCEL_LISTING_LABORER_CV_ASYNC,
  actionSuccess: Actions.CANCEL_LISTING_LABORER_CV_SUCCESS,
  actionFail: Actions.CANCEL_LISTING_LABORER_CV_FAIL,
  callApiFn: contractAuthAPI.cancelListingLaborerCV,
})

const getListingLaborerCVRequest = getSimpleRequestHandler<
  typeof contractAuthAPI.getListingLaborerCVRequest,
  ReturnType<typeof getListingLaborerCVRequestAction>
>({
  actionLoading: Actions.GET_LISTING_LABORER_CV_REQUEST_ASYNC,
  actionSuccess: Actions.GET_LISTING_LABORER_CV_REQUEST_SUCCESS,
  actionFail: Actions.GET_LISTING_LABORER_CV_REQUEST_FAIL,
  callApiFn: contractAuthAPI.getListingLaborerCVRequest,
})

const getListingCVsList = getSimpleRequestHandler<
  typeof contractAuthAPI.getListingCVsList,
  ReturnType<typeof getListingCVsListAction>
>({
  actionLoading: Actions.GET_LISTING_CVS_LIST_ASYNC,
  actionSuccess: Actions.GET_LISTING_CVS_LIST_SUCCESS,
  actionFail: Actions.GET_LISTING_CVS_LIST_FAIL,
  callApiFn: contractAuthAPI.getListingCVsList,
})

const selectListingCV = getSimpleRequestHandler<
  typeof contractAuthAPI.selectListingCV,
  ReturnType<typeof selectListingCVAction>
>({
  actionLoading: Actions.SELECT_LISTING_CV_ASYNC,
  actionSuccess: Actions.SELECT_LISTING_CV_SUCCESS,
  actionFail: Actions.SELECT_LISTING_CV_FAIL,
  callApiFn: contractAuthAPI.selectListingCV,
})

const getListingRequestList = getSimpleRequestHandler<
  typeof contractAuthAPI.getListingRequestList,
  ReturnType<typeof getListingRequestListAction>
>({
  actionLoading: Actions.GET_LISTING_REQUEST_LIST_ASYNC,
  actionSuccess: Actions.GET_LISTING_REQUEST_LIST_SUCCESS,
  actionFail: Actions.GET_LISTING_REQUEST_LIST_FAIL,
  callApiFn: contractAuthAPI.getListingRequestList,
})

const getListingRequest = getSimpleRequestHandler<
  typeof contractAuthAPI.getListingRequest,
  ReturnType<typeof getListingRequestAction>
>({
  actionLoading: Actions.GET_LISTING_REQUEST_ASYNC,
  actionSuccess: Actions.GET_LISTING_REQUEST_SUCCESS,
  actionFail: Actions.GET_LISTING_REQUEST_FAIL,
  callApiFn: contractAuthAPI.getListingRequest,
})

const extendListingRequest = getSimpleRequestHandler<
  typeof contractAuthAPI.extendListingRequest,
  ReturnType<typeof extendListingRequestAction>
>({
  actionLoading: Actions.EXTEND_LISTING_REQUEST_ASYNC,
  actionSuccess: Actions.EXTEND_LISTING_REQUEST_SUCCESS,
  actionFail: Actions.EXTEND_LISTING_REQUEST_FAIL,
  callApiFn: contractAuthAPI.extendListingRequest,
})

const resubmitListingRequest = getSimpleRequestHandler<
  typeof contractAuthAPI.resubmitListingRequest,
  ReturnType<typeof resubmitListingRequestAction>
>({
  actionLoading: Actions.RESUBMIT_LISTING_REQUEST_ASYNC,
  actionSuccess: Actions.RESUBMIT_LISTING_REQUEST_SUCCESS,
  actionFail: Actions.RESUBMIT_LISTING_REQUEST_FAIL,
  callApiFn: contractAuthAPI.resubmitListingRequest,
})

const disableListingRequest = getSimpleRequestHandler<
  typeof contractAuthAPI.disableListingRequest,
  ReturnType<typeof disableListingRequestAction>
>({
  actionLoading: Actions.DISABLE_LISTING_REQUEST_ASYNC,
  actionSuccess: Actions.DISABLE_LISTING_REQUEST_SUCCESS,
  actionFail: Actions.DISABLE_LISTING_REQUEST_FAIL,
  callApiFn: contractAuthAPI.disableListingRequest,
})

const getListingOffer = getSimpleRequestHandler<
  typeof contractAuthAPI.getListingOffer,
  ReturnType<typeof getListingOfferAction>
>({
  actionLoading: Actions.GET_LISTING_OFFER_ASYNC,
  actionSuccess: Actions.GET_LISTING_OFFER_SUCCESS,
  actionFail: Actions.GET_LISTING_OFFER_FAIL,
  callApiFn: contractAuthAPI.getListingOffer,
})

const acceptListingOffer = getSimpleRequestHandler<
  typeof contractAuthAPI.acceptListingOffer,
  ReturnType<typeof acceptListingOfferAction>
>({
  actionLoading: Actions.ACCEPT_LISTING_OFFER_ASYNC,
  actionSuccess: Actions.ACCEPT_LISTING_OFFER_SUCCESS,
  actionFail: Actions.ACCEPT_LISTING_OFFER_FAIL,
  callApiFn: contractAuthAPI.acceptListingOffer,
})

const rejectListingOffer = getSimpleRequestHandler<
  typeof contractAuthAPI.rejectListingOffer,
  ReturnType<typeof rejectListingOfferAction>
>({
  actionLoading: Actions.REJECT_LISTING_OFFER_ASYNC,
  actionSuccess: Actions.REJECT_LISTING_OFFER_SUCCESS,
  actionFail: Actions.REJECT_LISTING_OFFER_FAIL,
  callApiFn: contractAuthAPI.rejectListingOffer,
})

const submitEditListingRequest = getSimpleRequestHandler<
  typeof contractAuthAPI.submitEditListingRequest,
  ReturnType<typeof submitEditListingRequestAction>
>({
  actionLoading: Actions.SUBMIT_EDIT_LISTING_REQUEST_ASYNC,
  actionSuccess: Actions.SUBMIT_EDIT_LISTING_REQUEST_SUCCESS,
  actionFail: Actions.SUBMIT_EDIT_LISTING_REQUEST_FAIL,
  callApiFn: contractAuthAPI.submitEditListingRequest,
})

const getEmployerLaborersList = getSimpleRequestHandler<
  typeof contractAuthAPI.getEmployerLaborersList,
  ReturnType<typeof getEmployerLaborersListAction>
>({
  actionLoading: Actions.GET_EMPLOYER_LABORERS_LIST_ASYNC,
  actionSuccess: Actions.GET_EMPLOYER_LABORERS_LIST_SUCCESS,
  actionFail: Actions.GET_EMPLOYER_LABORERS_LIST_FAIL,
  callApiFn: contractAuthAPI.getEmployerLaborersList,
})

const getEmployerLaborerDetails = getSimpleRequestHandler<
  typeof contractAuthAPI.getEmployerLaborerDetails,
  ReturnType<typeof getEmployerLaborerDetailsAction>
>({
  actionLoading: Actions.GET_EMPLOYER_LABORER_DETAILS_ASYNC,
  actionSuccess: Actions.GET_EMPLOYER_LABORER_DETAILS_SUCCESS,
  actionFail: Actions.GET_EMPLOYER_LABORER_DETAILS_FAIL,
  callApiFn: contractAuthAPI.getEmployerLaborerDetails,
})

const checkDirectTransfersCount = getSimpleRequestHandler<
  typeof contractAuthAPI.checkDirectTransfersCount,
  ReturnType<typeof checkDirectTransfersCountAction>
>({
  actionLoading: Actions.CHECK_DIRECT_TRANSFERS_COUNT_ASYNC,
  actionSuccess: Actions.CHECK_DIRECT_TRANSFERS_COUNT_SUCCESS,
  actionFail: Actions.CHECK_DIRECT_TRANSFERS_COUNT_FAIL,
  callApiFn: contractAuthAPI.checkDirectTransfersCount,
})

export default [
  takeLatest(Actions.GET_CONTRACT_AUTH_EMLOYER_LABORERS, getEmployerLaborers),
  takeLatest(Actions.GET_CONTRACT_AUTH_REQUESTS_LIST, getContractAuthRequestsList),
  takeLatest(Actions.CREATE_CONTRACT_AUTH_REQUEST, createContractAuthRequest),
  takeLatest(Actions.GET_CONTRACT_AUTH_DETAILED_REQUEST, getContractAuthDetailedRequest),
  takeLatest(Actions.CANCEL_CONTRACT_AUTH_REQUEST, cancelContractAuthRequest),
  takeLatest(Actions.CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH, checkLaborerPhoneForContractAuth),
  takeLatest(Actions.CREATE_CONTRACT_AUTH_APPENDIX, createContractAuthRequestAppendix),
  takeLatest(Actions.CHECK_LABORERS_FOR_AUTH_BANNER, checkEmployerLaborersForAuthServiceBanner),
  takeLatest(Actions.UPDATE_LABORER_MOBILE_NUMBER, updateLaborerMobileNumber),
  takeLatest(Actions.CANCEL_CONTRACT_AUTH_APPENDIX, cancelContractAuthAppendix),
  takeLatest(Actions.GET_CONTRACT_AUTH_DRAFT, getAuthContractDraft),
  takeLatest(Actions.GET_SIMPLE_AUTH_CONTRACT_INFO, getSimpleAuthContractInfo),
  takeLatest(Actions.GET_AUTH_CONTRACT_PDF, getAuthContractPdf),
  takeLatest(Actions.GET_AUTH_CONTRACT, getAuthContract),
  takeLatest(Actions.GET_PENDING_APPENDIX, getPendingAppendix),
  takeLatest(Actions.SEND_LABORER_REPORT, sendLaborerReport),
  takeLatest(Actions.CANCEL_LABORER_REPORT, cancelLaborerReport),
  takeLatest(Actions.CHECK_LABORER_HAS_REPORT, checkLaborerHasReport),
  takeLatest(Actions.CHECK_LIST_LABORER_ELIGIBILITY, checkListLaborerEligibility),
  takeLatest(Actions.LIST_LABORER_CV, listLaborerCV),
  takeLatest(Actions.CANCEL_LISTING_LABORER_CV, cancelListingLaborerCV),
  takeLatest(Actions.GET_LISTING_LABORER_CV_REQUEST, getListingLaborerCVRequest),
  takeLatest(Actions.GET_LISTING_CVS_LIST, getListingCVsList),
  takeLatest(Actions.SELECT_LISTING_CV, selectListingCV),
  takeLatest(Actions.GET_LISTING_REQUEST_LIST, getListingRequestList),
  takeLatest(Actions.GET_LISTING_REQUEST, getListingRequest),
  takeLatest(Actions.EXTEND_LISTING_REQUEST, extendListingRequest),
  takeLatest(Actions.RESUBMIT_LISTING_REQUEST, resubmitListingRequest),
  takeLatest(Actions.DISABLE_LISTING_REQUEST, disableListingRequest),
  takeLatest(Actions.GET_LISTING_OFFER, getListingOffer),
  takeLatest(Actions.ACCEPT_LISTING_OFFER, acceptListingOffer),
  takeLatest(Actions.REJECT_LISTING_OFFER, rejectListingOffer),
  takeLatest(Actions.SUBMIT_EDIT_LISTING_REQUEST, submitEditListingRequest),
  takeLatest(Actions.GET_EMPLOYER_LABORERS_LIST, getEmployerLaborersList),
  takeLatest(Actions.GET_EMPLOYER_LABORER_DETAILS, getEmployerLaborerDetails),
  takeLatest(Actions.CHECK_DIRECT_TRANSFERS_COUNT, checkDirectTransfersCount),
]

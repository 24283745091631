import React from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorContent } from 'containers/ErrorBoundary/ErrorPage/components/ErrorContent/ErrorContent'
import { addTranslationNamespace, TranslationNamespace } from 'common/utils/translation'

import styles from './DisablePage.module.scss'
import langAr from './DisablePage_ar.json'
import langEn from './DisablePage_en.json'

const DisablePage = () => {
  const { t } = useTranslation(TranslationNamespace.disablePage)
  return (
    <div className={styles.container}>
      <ErrorContent withoutBtn title={t('title')} description={t('description')} />
    </div>
  )
}

export default DisablePage

addTranslationNamespace(TranslationNamespace.disablePage, langEn, langAr)

import { CheckTransfersCountFailResponse } from 'api/contractAuthAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  errorMessage: string
} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
  errorMessage: '',
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CHECK_DIRECT_TRANSFERS_COUNT_ASYNC,
    Actions.CHECK_DIRECT_TRANSFERS_COUNT_SUCCESS,
    Actions.CHECK_DIRECT_TRANSFERS_COUNT_FAIL,
    {},
    CheckTransfersCountFailResponse
  >,
) => {
  switch (action.type) {
    case Actions.CHECK_DIRECT_TRANSFERS_COUNT_SUCCESS:
    case Actions.CHECK_DIRECT_TRANSFERS_COUNT_ASYNC:
      return {
        ...state,
        ...action.status,
      }

    case Actions.CHECK_DIRECT_TRANSFERS_COUNT_FAIL:
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error.message || '',
      }
    default:
      return state
  }
}

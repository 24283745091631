import type { AxiosRequestConfig } from 'axios'

import { callAPI, laborerAuthAPIWithCredentials } from 'api/axios'
import { GeneralFailResponse, ListingCVRequestFromBE } from 'common/types/commonTypes'
import { LIST_ITEMS_PER_PAGE } from 'common/constants'

export type GetListingRequestsParams = {
  perPage?: number
  page?: number
}

export type GetListingRequestsResponse = {
  data: Array<ListingCVRequestFromBE>
  meta: {
    last_page: number
    current_page: number
  }
}

const getListingRequests = (
  { page = 1, perPage = LIST_ITEMS_PER_PAGE }: GetListingRequestsParams,
  config?: AxiosRequestConfig,
) =>
  laborerAuthAPIWithCredentials.get<GetListingRequestsResponse>('api/laborer/marketplace', {
    params: {
      page,
      per_page: perPage,
    },
    ...config,
  })

export type AcceptListingRequestParams = {
  requestId: number
}

export type AcceptListingRequestResponse = {}

const acceptListingRequest = ({ requestId }: AcceptListingRequestParams, config?: AxiosRequestConfig) =>
  laborerAuthAPIWithCredentials.post<AcceptListingRequestResponse>(`api/laborer/marketplace/${requestId}/approve`, {
    ...config,
  })

export type RejectListingRequestParams = {
  requestId: number
}

export type RejectListingRequestResponse = {}

const rejectListingRequest = ({ requestId }: RejectListingRequestParams, config?: AxiosRequestConfig) =>
  laborerAuthAPIWithCredentials.post<RejectListingRequestResponse>(`api/laborer/marketplace/${requestId}/reject`, {
    ...config,
  })

export type AcceptEditListingRequestParams = {
  requestId: number
}

export type AcceptEditListingRequestResponse = {}

const acceptEditListingRequest = ({ requestId }: AcceptEditListingRequestParams, config?: AxiosRequestConfig) =>
  laborerAuthAPIWithCredentials.post<AcceptEditListingRequestResponse>(
    `api/laborer/marketplace/edit_request/${requestId}/approve`,
    {
      ...config,
    },
  )

export type RejectEditListingRequestParams = {
  requestId: number
}

export type RejectEditListingRequestResponse = {}

const rejectEditListingRequest = ({ requestId }: RejectEditListingRequestParams, config?: AxiosRequestConfig) =>
  laborerAuthAPIWithCredentials.post<RejectEditListingRequestResponse>(
    `api/laborer/marketplace/edit_request/${requestId}/reject`,
    {
      ...config,
    },
  )

export default {
  getListingRequests: callAPI<typeof getListingRequests, GetListingRequestsResponse, GeneralFailResponse>(
    getListingRequests,
  ),
  acceptListingRequest: callAPI<typeof acceptListingRequest, AcceptListingRequestResponse, GeneralFailResponse>(
    acceptListingRequest,
  ),
  rejectListingRequest: callAPI<typeof rejectListingRequest, RejectListingRequestResponse, GeneralFailResponse>(
    rejectListingRequest,
  ),
  acceptEditListingRequest: callAPI<
    typeof acceptEditListingRequest,
    AcceptEditListingRequestResponse,
    GeneralFailResponse
  >(acceptEditListingRequest),
  rejectEditListingRequest: callAPI<
    typeof rejectEditListingRequest,
    RejectEditListingRequestResponse,
    GeneralFailResponse
  >(rejectEditListingRequest),
}
